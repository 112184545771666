import React, { useState } from "react";
import { Button, Modal, Input, DatePicker, TimePicker, message } from 'antd';
import People1 from "./assets/people1.svg"
import People2 from "./assets/people2.svg"

import moment from 'moment';

const PeopleModal = ({ isModalOpen, setIsModalOpen }) => {
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = () => {
        message.success("Form submitted successfully!")
        handleCancel()
    }

    return (
        <Modal mask={false} wrapClassName="custom-modal-wrapper" className="people-modal" footer={false} style={{ maxHeight: '100vh', borderRadius: 20, left: '15px' }} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <div style={{ position: 'absolute', bottom: '0', left: '-30px' }}>
                <img src={People1} />
            </div>
            <div style={{ position: 'absolute', bottom: '0', right: '-30px' }}>
                <img src={People2} />
            </div>
            <div style={{ paddingTop: '20px' }} />
            <span className="whitneybold" style={{ fontSize: '10vw', borderBottom: '1px solid black', width: '90px', paddingTop: '40px' }}>People</span>
            <p className="whitneylight" style={{ fontSize: 18 }}>Request Demo</p>

            <div style={{ paddingTop: '30px' }} />

            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '20px 0', borderRadius: 0 }} placeholder="Name" />
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '20px 0', borderRadius: 0 }} placeholder="Company" />
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '20px 0', borderRadius: 0 }} placeholder="Email-id" />
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '20px 0', borderRadius: 0 }} placeholder="Phone no." />
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '20px 0', borderRadius: 0 }} placeholder="Call Date" />
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '20px 0', borderRadius: 0 }} placeholder="Call Time" />
            <div style={{ paddingTop: '5vh' }} />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button onClick={handleSubmit} style={{ backgroundColor: 'black', color: 'white', borderRadius: 12 }}>Submit</Button>

            </div>
        </Modal>
    );
};

export default PeopleModal;
