import React, { useState, useRef } from "react";
import { Button, Modal, Input, DatePicker, TimePicker, message } from 'antd';
import sitemaster1 from "./assets/sitemaster1.svg"
import sitemaster2 from "./assets/sitemaster2.svg"

import moment from 'moment';
import { exitFullscreen } from "../lib/fullscreen";

const SitemasterModal = ({ isModalOpen, setIsModalOpen }) => {
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        exitFullscreen()
    };
    const handleSubmit = () => {
        message.success("Form submitted successfully!")
        handleCancel()

    }

    return (
        <Modal mask={false} wrapClassName="custom-modal-wrapper" className="people-modal" footer={false} style={{ maxHeight: '100vh', borderRadius: 20, left: '15px' }} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <div style={{ position: 'absolute', top: '-50px', left: '20px' }}>
                <img src={sitemaster1} />
            </div>
            <div  style={{ position: 'absolute', bottom: '-50px', right: '60px' }}>
                <img width={'170%'} src={sitemaster2} />
            </div>
            <div style={{ paddingTop: '20px' }} />
            <span className="whitneybold" style={{ fontSize: '10vw', borderBottom: '1px solid black', width: '90px', paddingTop: '40px' }}>SiteMaster</span>
            <p className="whitneylight" style={{ fontSize: 18 }}>Request Demo</p>

            <div style={{ paddingTop: '2vh' }} />

            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Name" />
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Company" />
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Email-id" />
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Phone no." />
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Call Date" />
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Call Time" />
            <div style={{ paddingTop: '5vh' }} />
            <div>
                <Button onClick={handleSubmit} style={{ backgroundColor: 'black', color: 'white', borderRadius: 12 }}>Submit</Button>

            </div>
        </Modal>
    );
};

export default SitemasterModal;
