import React from "react";
import Button from "./component/Button";
import Footer from "./component/footer";
import Header from "./component/header";

const Services = () => {
    return (
        <>
            <Header />
            <section

                style={{
                    overflowX: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    paddingLeft: '30px',
                    transition: 'opacity 1s',
                    overflow: 'hidden',
                    paddingTop : '10%'
                }}>

                <div style={{ marginBottom: '50px' }} />
                <span className={'WhitneySemiboldSc bold-whitney'}>Think / <span className={'whitneylight bold-light'}>Design</span></span>
                <p className='light-description'>At Qnix, we understand that<br />
                    exceptional user experiences <br />
                    are at the core of successful<br />
                    software products. Our UX<br />
                    experts are equipped with the <br />
                    tools and methodologies to <br />

                </p>
                <Button />
                <div style={{ paddingTop: '18%' }} />

                <span className={'WhitneySemiboldSc bold-whitney'}>Execute / <span className={'whitneylight bold-light'}>Develop</span></span>
                <p className='light-description'>At Qnix, we understand that<br />
                    exceptional user experiences <br />
                    are at the core of successful<br />
                    software products. Our UX<br />
                    experts are equipped with the <br />
                    tools and methodologies to <br />
                </p>
                <Button />

                <div style={{ paddingTop: '18%' }} />


                <span className={'WhitneySemiboldSc bold-whitney'}>Optimize / <span className={'whitneylight bold-light'}>Automate</span></span>
                <p className='light-description'>At Qnix, we understand that<br />
                    exceptional user experiences <br />
                    are at the core of successful<br />
                    software products. Our UX<br />
                    experts are equipped with the <br />
                    tools and methodologies to <br />
                </p>
                <Button />

                <div style={{ paddingTop: '18%' }} />


                <span className={'WhitneySemiboldSc bold-whitney'}>Amplify / <span className={'whitneylight bold-light'}>Ai & Ml</span></span>
                <p className='light-description'>At Qnix, we understand that<br />
                    exceptional user experiences <br />
                    are at the core of successful<br />
                    software products. Our UX<br />
                    experts are equipped with the <br />
                    tools and methodologies to <br />
                </p>
                <Button />

            </section>
            <section style={{ overflowX: 'hidden', width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'end', paddingLeft: '30px', paddingBottom: '20px' }}>
                <Footer />
            </section>
        </>
    )

}
export default Services