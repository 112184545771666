export function toggleFullscreen() {
    if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen().catch((err) => {
            console.log(`Error attempting to enable full-screen mode: ${err.message}`);
        });
    }


}

export function exitFullscreen() {
    if (document.fullscreenElement) {
        document.exitFullscreen().catch((err) => {
            console.log(`Error attempting to exit full-screen mode: ${err.message}`);
        });
    }
}
