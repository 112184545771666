
import React, { useState } from "react";
import { Button, Modal, Input, DatePicker, TimePicker, message } from 'antd';
import QueryImage from "./assets/query.svg"

import moment from 'moment';

const { TextArea } = Input;
const QueryModal = ({ isModalOpen, setIsModalOpen }) => {
    
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = () => {
        message.success("Form submitted successfully!")
        handleCancel()
    }

    return (
        <Modal className="query-modal" mask={false} wrapClassName="custom-modal-wrapper" footer={false} style={{ maxHeight: '100vh', borderRadius: 20, left: '15px', top: '25%', zIndex: 999 }} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <div style={{ position: 'absolute', top: '-106px', left: '20vw', zIndex:-1 }}>
                <img src={QueryImage} />
            </div>

            <div style={{ paddingTop: '20px' }} />
            <span className="whitneybold" style={{ fontSize: '10vw', width: '90px', paddingTop: '40px' }}>Query</span>

            <div style={{ paddingTop: '30px' }} />

            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Name" />

            <TextArea bordered={false } style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} rows={4} placeholder="Query" maxLength={6} />
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Email-id" />
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Phone no." />
            {/* <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '20px 0', borderRadius: 0 }} placeholder="Call Date" />
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '20px 0', borderRadius: 0 }} placeholder="Call Time" /> */}
            
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button onClick={handleSubmit} style={{ backgroundColor: 'black', color: 'white', borderRadius: 12 }}>Submit</Button>

            </div>
        </Modal>
    );
};

export default QueryModal;
