import React from "react";
import OurWorkAndroid from "./assets/ourWorkAndroid.svg"
import WorkspaceAndroid from "./assets/workspace-android.svg"
import PeopleAndroid from "./assets/people-android.svg"
import SitemasterAndroid from "./assets/sitemaster-android.svg"
import Footer from "./component/footer";
import Header from "./component/header";
const Work = () => {
    return (
        <>
            <Header />
            <section style={{ overflowX: 'hidden', display: 'flow', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', transition: 'opacity 1s', paddingTop:'10%' }}>

                <div style={{ marginBottom: '50px' }} />
               
                <a href='/WorkSpace'>
                    <div style={{}} className='svg-div'>
                        <p className='work-section-text'>Work Collaboration platform</p>
                        <div style={{ paddingLeft: '10px' }}>
                            <p className='more-text-1'>more..</p>

                        </div>
                    </div>

                    <img style={{ paddingLeft: '20px' }} width={'95%'} src={WorkspaceAndroid} />
                </a>
                <a href='/People'>
                    <div className='svg-div'>
                        <p className='work-section-text'>People & productivity management </p>
                        <div style={{ paddingLeft: '10px' }}>
                            <p className='more-text-1'>more..</p>

                        </div>
                    </div>
                    <img style={{ paddingLeft: '20px' }} width={'95%'} src={PeopleAndroid} />
                </a>
                <a href='/SiteMaster'>
                    <div className='svg-div'>
                        <p className='work-section-text'>Telecom site management</p>
                        <div style={{ paddingLeft: '10px' }}>
                            <p className='more-text-1'>more..</p>
                        </div>
                    </div>
                    <img style={{ paddingLeft: '20px' }} width={'95%'} src={SitemasterAndroid} />
                </a>
                {/* <img width={'100%'} src={SteelAndroid} />
            <img width={'100%'} src={MobileIdAndroid} /> */}
            </section>
            <section style={{ overflowX: 'hidden', width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'end', paddingLeft: '30px', paddingBottom: '20px' }}>
                <Footer />
            </section>
        </>
    )

}
export default Work