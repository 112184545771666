import * as React from "react";
import QueryModal from "../../modals/QueryModal";
import CareerModal from "../../modals/CareerModal";
import ContactModal from "../../modals/ContatctModal";
const Footer = (props) => {

  const [isQueryModalOpen, setIsQueryModalOpen] = React.useState(false);
  const [isCareerModalOpen, setIsCareerModalOpen] = React.useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = React.useState(false)
  const careerModalVisible = () => {
    setIsCareerModalOpen(!isCareerModalOpen)
  }

  const contactModalVisible = () => {
    setIsContactModalOpen(!isContactModalOpen)
  }

  const modalVisible = () => {
    setIsQueryModalOpen(!isQueryModalOpen)
  }
  return (
    <div>
      <QueryModal
        isModalOpen={isQueryModalOpen}
        setIsModalOpen={setIsQueryModalOpen}
      />

      <CareerModal
        isModalOpen={isCareerModalOpen}
        setIsModalOpen={setIsCareerModalOpen}
      />

      <ContactModal
        isModalOpen={isContactModalOpen}
        setIsModalOpen={setIsContactModalOpen}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={'100%'}
        height={'auto'}
        viewBox="0 0 318 653.059"
        {...props}
      >
        <text
          id="List"
          transform="translate(2 238.714)"
          fill="#484848"
          fontSize={12}
          fontFamily="Whitney-Medium, Whitney"
          fontWeight={500}
          letterSpacing="0.019em"
        >
          <a href="/UxDesign">
            <tspan x={0} y={11}>
              {"UX Design"}
            </tspan>
          </a>
          <a href="/UxDesign">
            <tspan x={0} y={41}>
              {"Product Design"}
            </tspan>
          </a>
          <a href="/Development">
            <tspan x={0} y={71}>
              {"Product development"}
            </tspan>
          </a>
          <a href="/Automation">
            <tspan x={0} y={101}>
              {"Automation"}
            </tspan>
          </a>
          <a href="/AiMl">
            <tspan x={0} y={131}>
              {"AI & ML services"}
            </tspan>
          </a>
        </text>
        <text
          id="Title"
          transform="translate(2.725 196.717)"
          fill="#484848"
          fontSize={18}
          fontFamily="Whitney-Bold, Whitney"
          fontWeight={700}
          letterSpacing="0.017em"
        >
          <tspan x={0} y={17}>
            {"Services"}
          </tspan>
        </text>
        <text
          id="List-2"
          data-name="List"
          transform="translate(194.091 238.714)"
          fill="#484848"
          fontSize={12}
          fontFamily="Whitney-Medium, Whitney"
          fontWeight={500}
          letterSpacing="0.019em"
        >
          <a href="/WorkSpace">
            <tspan x={0} y={11}>
              {"Qelza Workspace"}
            </tspan>
          </a>
          <a href="/People">
            <tspan x={0} y={41}>
              {"Qelza People"}
            </tspan>
          </a>
          <a href="/SiteMaster" >
            <tspan x={0} y={71}>
              {"Site Master"}
            </tspan>
          </a>
          <a href="">
            <tspan x={0} y={101}>
              {"Qelza Inventory"}
            </tspan>
          </a>
          <a href="">
            <tspan x={0} y={131}>
              {"Qelza Recce"}
            </tspan>
          </a>
        </text>
        <text
          id="Title-2"
          data-name="Title"
          transform="translate(193.906 196.717)"
          fill="#484848"
          fontSize={18}
          fontFamily="Whitney-Bold, Whitney"
          fontWeight={700}
          letterSpacing="0.017em"
        >
          <tspan x={0} y={17}>
            {"Products"}
          </tspan>
        </text>
        <text
          id="List-3"
          data-name="List"
          transform="translate(2 447.049)"
          fill="#484848"
          fontSize={12}
          fontFamily="Whitney-Medium, Whitney"
          fontWeight={500}
          letterSpacing="0.019em"
        >
          <a href="/#about-screen"> 
            <tspan x={0} y={11}>
              {"About"}
            </tspan>
          </a>
          <a href="/#ourwork-screen">
            <tspan x={0} y={41}>
              {"Our work"}
            </tspan>
          </a>
          <a href="/#clients-screen">
            <tspan x={0} y={71}>
              {"Clients "}
            </tspan>
          </a>
        </text>
        <text
          id="Title-3"
          data-name="Title"
          transform="translate(2 405.332)"
          fill="#484848"
          fontSize={18}
          fontFamily="Whitney-Bold, Whitney"
          fontWeight={700}
          letterSpacing="0.017em"
        >
          <tspan x={0} y={17}>
            {"Company"}
          </tspan>
        </text>
        <text
          id="List-4"
          data-name="List"
          transform="translate(196.479 447.329)"
          fill="#484848"
          fontSize={12}
          fontFamily="Whitney-Medium, Whitney"
          fontWeight={500}
          letterSpacing="0.019em"
        >
          <tspan onClick={modalVisible} x={0} y={11}>
            {"Query form"}
          </tspan>
          <tspan onClick={careerModalVisible} x={0} y={41}>
            {"Careers"}
          </tspan>
          <tspan onClick={contactModalVisible} x={0} y={71}>
            {"Contact support"}
          </tspan>
        </text>
        <text
          id="Title-4"
          data-name="Title"
          transform="translate(196.479 405.332)"
          fill="#484848"
          fontSize={18}
          fontFamily="Whitney-Bold, Whitney"
          fontWeight={700}
          letterSpacing="0.017em"
        >
          <tspan x={0} y={17}>
            {"Resources"}
          </tspan>
        </text>
        <text
          id="copyright"
          transform="translate(0 619.059)"
          fill="#92989f"
          fontSize={9}
          fontFamily="Whitney-Medium, Whitney"
          fontWeight={500}
          letterSpacing="0.021em"
        >
          <tspan x={0} y={9} xmlSpace="preserve">
            {" \xA9 Qnix innovation. All right reserved"}
          </tspan>
        </text>
        <g
          id="Group_3953"
          data-name="Group 3953"
          transform="translate(-34 -1687.273)"
        >
          <rect
            id="Rectangle_4708"
            data-name="Rectangle 4708"
            width={18.716}
            height={10.764}
            transform="translate(38 1786)"
            fill="#fff"
          />
          <g id="icon" transform="translate(36 1783.885)">
            <path
              id="Email"
              d="M22.716,14.764H0V0H22.716V14.763ZM2.271,4.493v8.426H20.445V4.493L11.358,9.415Zm0-2.647v.429l9.087,4.92,9.087-4.92V1.845Z"
              fill="#484848"
            />
          </g>
        </g>
        <g
          id="Group_3954"
          data-name="Group 3954"
          transform="translate(161.534 -1735.687)"
        >
          <path
            id="Path_2696"
            data-name="Path 2696"
            d="M3542.1,305.313c-.789.418-2.558,1.674-2.558,1.674l-1,2.309.46,3.274,1.194,3.369,1.9,2.038,3.2,3.073,3.816,1.077,3.074.285,2.781-.839,1.231-1.954.831-2.477-5.387-.511-.588,1.671-1.01.607-3.632-1.767-2.222-2.515-1.5-2.057-.6-1.233,3.2-1.755-2.608-4.269S3542.892,304.895,3542.1,305.313Z"
            transform="translate(-3501.583 1525.489)"
            fill="#f5f5f5"
          />
          <g id="icon-2" data-name="icon" transform="translate(36 1829.77)">
            <path
              id="Phone"
              d="M13.775,19.025c-3.031,0-6.51-1.587-9.308-4.246A14.215,14.215,0,0,1,.19,7.675a6.2,6.2,0,0,1,1.521-6A5.855,5.855,0,0,1,5.015.1L5.757,0,8.483,6.044l-2.726,1.3A15.183,15.183,0,0,0,8.6,10.848a16.211,16.211,0,0,0,3.674,2.683l1.281-2.384L19.874,12l.038.766a5.774,5.774,0,0,1-1.663,4.63A6.333,6.333,0,0,1,13.775,19.025ZM4.581,2.1h0a3.836,3.836,0,0,0-1.493.887C2,4.016,1.7,5.688,2.225,7.694a12.724,12.724,0,0,0,3.619,5.775,12.454,12.454,0,0,0,7.966,3.677,4.338,4.338,0,0,0,3.06-1.057,3.692,3.692,0,0,0,1.082-2.474l-3.281-.446-.63,1.2a1.9,1.9,0,0,1-1.155.936,2.037,2.037,0,0,1-.587.085,2.093,2.093,0,0,1-.956-.23,18.141,18.141,0,0,1-4.121-3A17.126,17.126,0,0,1,4.037,8.222,1.78,1.78,0,0,1,3.9,6.775a1.877,1.877,0,0,1,.987-1.1l1.078-.512L4.581,2.1Z"
              transform="translate(0)"
              fill="#484848"
            />
          </g>
        </g>
        <g
          id="Component_9_1"
          data-name="Component 9 \u2013 1"
          transform="translate(2)"
        >
          <text
            id="Let_s_talk_"
            data-name="Let\u2019s talk "
            transform="translate(0 38)"
            fill="#272727"
            fontSize={40}
            fontFamily="Whitney-Bold, Whitney"
            fontWeight={700}
          >
            <tspan x={0} y={0}>
              {"Let\u2019s talk "}
            </tspan>
          </text>
        </g>
        <text
          id="agensi_mail.com"
          data-name="agensi@mail.com"
          transform="translate(2 140.105)"
          fill="#484848"
          fontSize={16}
          fontFamily="Poppins-SemiBold, Poppins"
          fontWeight={600}
          letterSpacing="0.019em"
        >
          <a href="mailto:info@qnix.in">
            <tspan x={0} y={0}>
              {"info@qnix.in"}
            </tspan>
          </a>
        </text>
        <text
          id="_12_3456_7890"
          data-name="+ 12 3456 7890"
          transform="translate(194 138.22)"
          fill="#484848"
          fontSize={14}
          fontFamily="Poppins-SemiBold, Poppins"
          fontWeight={600}
          letterSpacing="0.019em"
        >
          <a href="tel:+918792771294">
            <tspan x={0} y={0}>
              {"+918792771294"}
            </tspan>
          </a>
        </text>
      </svg>
    </div>
  );
}
export default Footer;
