import React, { useEffect, useState, useRef } from 'react';
import Header from './component/header';
import WhatWeDo from './component/what-we-do';
import Footer from './component/footer';
import newSvg from "./assets/clients.svg"
import Cocacola from "../images/Coca-Cola_logo.png"
import hummel from "../images/hummel_logo.png"
import leo from "../images/01.png"
import threedr from "../images/3rdi_logo1.png"
import bpk from "../images/bpk.png"
import Qogno from "../images/Qogno_Logo1.png"
import HelloWorld from "../images/Hello  Mobile.png"
import cardServe from "../images/Cardserv.png"
import logo1 from "../images/logo1.svg"
import logo2 from "../images/logo2.svg"
import matlabs from "../images/Madlabs.svg"
import TDC from "../images/TDC 1.png"
import Proto from "../images/proto.png"
import "./slide.scss"
import WorkspaceAndroid from "./assets/workspace-android.svg"
import PeopleAndroid from "./assets/people-android.svg"
import SitemasterAndroid from "./assets/sitemaster-android.svg"
import SteelAndroid from "./assets/steel-android.svg"
import MobileIdAndroid from "./assets/mobileId-android.svg"
import FirstImage from "./newdesignsvg/firstSvg.svg"
import SecondImage from "./newdesignsvg/secondSvg.svg"
import { Link, Element, scroller } from 'react-scroll';



const Qnix = () => {

    const [scrollPosition, setScrollPosition] = useState(0);
    const [backgroundColor, setBackgroundColor] = useState(false)
    const [isiPhone, setIsiPhone] = useState(false);
    const [divScroll, setDivscroll] = useState(true);
    const [ourworkScroll, setOurWorkScroll] = useState(true)
    const [whatwedoDisplay, setWhatwedoDisplay] = useState(0.1)
    const [headerColor, setHeaderColor] = useState("black")
    const [ourWorkVisible, setOurWorkVisible] = useState(false)
    const [whatwedo, setWhatwedo] = useState(false)
    const scrollPreventRef = useRef(null);
    const spanRef = useRef(null)
    const ideasRef = useRef(null)
    const technologyRef = useRef(null)
    const whatwedoRef = useRef(null);
    const containerRef = useRef();
    const ourWorkRef = useRef();
    const workspaceRef = useRef()
    const titleRef3 = useRef(null)
    const titleRef = useRef(null);
    const titleRef2 = useRef(null);
    const [isSticky, setSticky] = useState(false);
    const [textSticky, setTextSticky] = useState(false);
    const [scrollPreventSticky, setScrollPreventSticky] = useState(false)
    const [displayText, setDisplayText] = useState(false)
    const [workTextDisplay, setWorkDisplay] = useState(false)
    const [scrollPreventText, setScrollPreventText] = useState(false)


    useEffect(() => {
        const userAgent = navigator.userAgent;

        if (userAgent.toLowerCase().includes('iphone')) {
            setIsiPhone(true);
        }
    }, []);

    const handleScroll = () => {
        if (whatwedoRef.current && titleRef.current) {
            const rect = whatwedoRef.current.getBoundingClientRect();
            if (rect.bottom >= 340) {
                setSticky(rect.top <= 50)
                setDisplayText(rect.bottom >= 370);
            }

        } else {
            setDisplayText(false);
            setSticky(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll2 = () => {
        if (workspaceRef.current && titleRef2.current) {
            const rect = workspaceRef.current.getBoundingClientRect();
            if (rect.bottom >= 370) {
                setTextSticky(rect.top <= -10)
                setWorkDisplay(rect.bottom >= 406);
            }

        } else {
            setWorkDisplay(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll2);
        return () => {
            window.removeEventListener('scroll', handleScroll2);
        };
    }, []);

    const handleScroll3 = () => {
        if (scrollPreventRef.current && titleRef3.current) {
            const rect = scrollPreventRef.current.getBoundingClientRect();
            if (rect.bottom >= 340) {
                setScrollPreventSticky(rect.top <= -100)
                setScrollPreventText(rect.bottom >= 370);
            }

        } else {
            setScrollPreventSticky(false);
            setScrollPreventText(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll3);
        return () => {
            window.removeEventListener('scroll', handleScroll3);
        };
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPosition = window.scrollY;
            setScrollPosition(currentScrollPosition);
            if (currentScrollPosition > 80 && divScroll) {
                window.scrollTo(0, 80)
                setTimeout(() => {
                    setDivscroll(false)
                }, 800);
            }

        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [divScroll]);

    useEffect(() => {
        const what = whatwedoRef.current
        const workspace = workspaceRef.current
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.2
        };
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
                // workspace.style.opacity = 1

            } else {
                // workspace.style.opacity = 0
                // what.style.opacity = 1
                setTextSticky(false)
            }
        }, options);

        if (workspace) {
            observer.observe(workspace);
        }
        return () => {
            if (workspace) {
                observer.unobserve(workspace);
            }
        };
    }, [textSticky]);

    useEffect(() => {
        const what = whatwedoRef.current

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.2
        };
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {


            } else {
                setSticky(false)
            }
        }, options);

        if (what) {
            observer.observe(what);
        }
        return () => {
            if (what) {
                observer.unobserve(what);
            }
        };
    }, [isSticky]);

    useEffect(() => {
        const what = scrollPreventRef.current

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.2
        };
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {


            } else {
                setScrollPreventSticky(false)
            }
        }, options);

        if (what) {
            observer.observe(what);
        }
        return () => {
            if (what) {
                observer.unobserve(what);
            }
        };
    }, [isSticky]);

    useEffect(() => {
        const section = ourWorkRef.current

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
                setOurWorkVisible(true)
            } else {
                setOurWorkVisible(false)
            }
        }, options);

        if (ourWorkRef.current) {
            observer.observe(ourWorkRef.current);
        }
        return () => {
            if (ourWorkRef.current) {
                observer.unobserve(ourWorkRef.current);
            }
        };
    }, [ourWorkVisible]);

    useEffect(() => {
        const section = technologyRef.current
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
                setHeaderColor("white")
            } else {
                setHeaderColor("black")
            }
        }, options);

        if (technologyRef.current) {
            observer.observe(technologyRef.current);
        }
        return () => {
            if (technologyRef.current) {
                observer.unobserve(technologyRef.current);
            }
        };
    }, []);

    // useEffect(() => {

    //     const whatwedo = whatwedoRef.current
    //     const image = imageRef.current

    //     const options = {
    //         root: null,
    //         rootMargin: '0px',
    //         threshold: 0.25
    //     };
    //     const observer = new IntersectionObserver((entries) => {
    //         const entry = entries[0];
    //         if (entry.isIntersecting) {
    //             image.style.opacity = 1;

    //         } else {
    //             image.style.opacity = 0;

    //         }
    //     }, options);

    //     if (whatwedoRef.current) {
    //         observer.observe(whatwedoRef.current);
    //     }
    //     return () => {
    //         if (whatwedoRef.current) {
    //             observer.unobserve(whatwedoRef.current);
    //         }
    //     };
    // }, []);

    useEffect(() => {

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
                setHeaderColor("black")
            } else {
                setHeaderColor("white")

            }
        }, options);

        if (ideasRef.current) {
            observer.observe(ideasRef.current);
        }
        return () => {
            if (ideasRef.current) {
                observer.unobserve(ideasRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (isiPhone) {
            const image = spanRef.current;

            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.15,
            };

            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setBackgroundColor(true)
                    } else {
                        setBackgroundColor(false)
                    }
                });
            }, options);

            if (image) {
                observer.observe(image);
            }

            return () => {
                if (image) {
                    observer.unobserve(image);
                }
            };
        }

    }, [backgroundColor]);

    useEffect(() => {
        const image = whatwedoRef.current;

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.2,
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setWhatwedo(true)
                } else {
                    setWhatwedo(false)
                }
            });
        }, options);

        if (image) {
            observer.observe(image);
        }

        return () => {
            if (image) {
                observer.unobserve(image);
            }
        };

    }, [backgroundColor]);



    const scrollToSection = (section) => {
        scroller.scrollTo(section, {
            duration: 100,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    };

    return (
        <div style={{ overflowX: 'hidden' }}>
            {!backgroundColor && (
                <div style={{ position: 'absolute', left: '50%' }} >
                    <Header scrollToSection={scrollToSection} transform={isSticky ? isSticky : textSticky ? textSticky : scrollPreventSticky} backgroundColor={scrollPosition > 60 && scrollPosition < 600 ? 'white' : ourWorkVisible ? 'white' : headerColor} />
                </div>
            )}
            <section className="work-section" style={{ overflowX: 'hidden', flexDirection: 'column', background: scrollPosition > 60 ? 'black' : 'transparent', paddingTop: '30%', paddingLeft: '30px', height: '100vh' }} id="main-header">
                {scrollPosition > 60 ? (
                    <div ref={ideasRef} className="hero-text" style={{
                        opacity: scrollPosition > 60 ? 1 : 0,
                        animation: 'fadeIn 1s',
                        overflowX: 'hidden'

                    }}>
                        <div className="second-svg-text">
                            <span className="second-svg-text-line medium">ideas</span>
                            <br />
                            <span className="second-svg-text-line">into</span>
                            <br />
                            <span className="second-svg-text-line">reality</span>
                        </div>
                        <div style={{ paddingTop: '20px' }} />
                        <div className={`linewhite`}></div>
                    </div>
                ) : (
                    <div style={{ paddingBottom: '50%', overflowX: 'hidden' }} className="hero">
                        <div className="first-svg-text" style={{
                            opacity: scrollPosition > 60 ? 0 : 1,
                            animation: 'fadeIn 1s',
                            textAlign: 'left',

                        }}>
                            <span className="first-svg-text-line">design</span>

                            <span className="first-svg-text-line">&amp;</span>

                            <span className="first-svg-text-line medium">develop</span>
                        </div>
                        <div style={{ paddingTop: '20px' }} />
                        <div className="line"></div>
                    </div>
                )}


            </section>
            <div id='about-screen' ref={technologyRef} className='technology work-section' style={{ backgroundColor: scrollPosition > 60 ? 'black' : 'white', animation: 'fadeIn 1s', flexDirection: 'column', maxHeight: '80vh', minHeight: '80vh', paddingTop: '20%' }}>
                <div style={{ paddingLeft: '31px', paddingRight: '31px', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', bottom: '10%' }}>
                    <p style={{ fontWeight: '500', fontSize: '3.5vh', transition: 'opacity 1s', color: 'white', zIndex: 1, position: 'relative', }}>
                        We are <span style={{ fontWeight: '100' }}>a design & technology company focused on building solutions that help businesses perform better</span>
                    </p>
                    <img
                        style={{ width: '70vw', position: 'absolute', bottom: '-43vh', left: '50%', transform: 'translateX(-50%)' }}
                        src={FirstImage}
                        alt="Your Alt Text"
                    />
                </div>
            </div>

            {/* <section style={{maxHeight : '20vh'}}/> */}

            <section
                className='our-work'
                style={{
                    overflowX: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    minHeight: "100vh",
                    paddingRight: '30px',
                    flexDirection: 'column',
                    paddingTop: '-10%',
                    overflow: 'hidden',
                    paddingLeft: '30px',

                }}
            >
                <p style={{ fontWeight: '500', fontSize: '3.5vh', opacity: 1, transition: 'opacity 0.1s' }}>
                    Our design <span style={{ fontWeight: '100' }}>process
                        <br />
                        places users at the
                        <br />
                        core of problem -
                        <br />
                        solving, ensuring <br /> our solutions
                        are<br /> both  impactful <br />
                        and  effective</span>
                </p>
                <img src={SecondImage} style={{ width: '60vw', position: 'absolute', bottom: '9vh', left: '93vw', transform: 'translateX(-50%)', overflowX: 'hidden' }}
                />
            </section >
            <Element name="Service">
                <section

                    ref={whatwedoRef}
                    style={{
                        overflowX: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        paddingLeft: '30px',
                        transition: 'opacity 1s',
                        overflow: 'hidden'
                    }}>
                    {isSticky && (
                        <p
                            className='title-text whitneybold'

                            style={{
                                position: 'fixed',
                                top: '3.5%',
                                zIndex: 100,
                                display: displayText ? 'flex' : 'none',
                                flexDirection: 'column',
                                fontSize: '20px',
                                opacity: isSticky ? 1 : 0
                            }}
                        >
                            What we do
                            <div
                                style={{
                                    width: '20px',
                                    height: '2px',
                                }}
                                className='text-line'
                            ></div>
                        </p>
                    )}


                    <p
                        className='title-text whitneybold'
                        ref={titleRef}
                        style={{
                            zIndex: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            opacity: isSticky ? 0 : 1

                        }}
                    >
                        What we do
                        <div
                            style={{
                                width: isSticky ? '20px' : '',
                                height: isSticky ? '2px' : '',
                            }}
                            className='text-line'
                        ></div>
                    </p>
                    <div style={{ paddingTop: '14%' }} />

                    <WhatWeDo />


                </section>
            </Element>

            <section style={{ minHeight: '1vh' }} />
            <Element name="Work">
                <section id='ourwork-screen' ref={workspaceRef} style={{ overflowX: 'hidden', display: 'flow', flexDirection: 'column', transition: 'opacity 1s' }}>

                    <div style={{ marginBottom: '50px' }} />
                    <div>
                        {textSticky && (
                            <p
                                className='title-text whitneylight'
                                style={{
                                    position: 'fixed',
                                    top: '3.5%',
                                    zIndex: 100,
                                    display: workTextDisplay ? 'flex' : 'none',
                                    flexDirection: 'row',
                                    fontSize: '20px',
                                    paddingLeft: '30px',

                                }}
                            >
                                Our <span style={{ marginLeft: '5px' }} className='whitneybold'>Work</span>
                                <br />
                                <div
                                    style={{
                                        width: textSticky ? '20px' : '',
                                        height: textSticky ? '2px' : '',
                                        position: 'absolute',
                                        top: '100%',
                                    }}
                                    className='text-line'
                                ></div>



                            </p>
                        )}


                        <p
                            className='title-text whitneylight'
                            ref={titleRef2}
                            style={{
                                zIndex: 100,
                                display: 'flex',
                                flexDirection: 'row',
                                paddingLeft: '30px',
                                paddingTop: '20px',
                                opacity: textSticky ? 0 : 1

                            }}
                        >
                            Our <span style={{ marginLeft: '5px' }} className='whitneybold'>Work</span>
                            <br />
                            <div
                                style={{

                                    position: 'absolute',
                                    top: '120px'
                                }}
                                className='text-line'
                            ></div>



                        </p>
                        {/* <img width={'15%'} style={{ marginRight: '10vw', position: 'absolute', top: textSticky ? '-10px' : '4.5vh', right:'2%' }} src={ThirdImage} /> */}
                    </div>

                    <div style={{ paddingTop: '10%' }} />
                    <a href='/WorkSpace'>
                        <div style={{ paddingTop: 0 }} className='svg-div'>
                            <div style={{ paddingTop: 0 }} className='svg-div'>
                                <p className='work-bold-text'>Workspace&nbsp;&nbsp;:&nbsp;&nbsp;<span className='work-light-text'>Collaboration platform</span></p>
                            </div>
                        </div>

                        <img style={{ paddingLeft: '20px' }} width={'95%'} src={WorkspaceAndroid} />
                    </a>
                    <a href='/People'>
                        <div className='svg-div'>
                            <div style={{}} className='svg-div'>
                                <p className='work-bold-text'>People&nbsp;&nbsp;:&nbsp;&nbsp;<span className='work-light-text'>HR & productivity management </span></p>
                            </div>
                        </div>
                        <img style={{ paddingLeft: '20px' }} width={'95%'} src={PeopleAndroid} />
                    </a>
                    <a href='/SiteMaster'>
                        <div className='svg-div'>
                            <div style={{}} className='svg-div'>
                                <p className='work-bold-text'>Sitemaster&nbsp;&nbsp;:&nbsp;&nbsp;<span className='work-light-text'>Tower site management</span></p>
                            </div>
                        </div>
                        <img style={{ paddingLeft: '20px' }} width={'95%'} src={SitemasterAndroid} />
                    </a>
                    <a href='/SteelMobile'>
                        <div className='svg-div'>
                            <div style={{}} className='svg-div'>
                                <p className='work-bold-text'>BPK&nbsp;&nbsp;:&nbsp;&nbsp;<span className='work-light-text'>Steel Trading platform</span></p>
                            </div>
                        </div>
                        <img style={{ paddingLeft: '20px' }} width={'95%'} src={SteelAndroid} />
                    </a>
                    <a href='/Steel'>
                    <div className='svg-div'>
                        <div style={{}} className='svg-div'>
                            <p className='work-bold-text'>Mobile Id&nbsp;&nbsp;:&nbsp;&nbsp;<span className='work-light-text'>DIgital Identification</span></p>
                        </div>
                    </div>
                    <img style={{ paddingLeft: '20px' }} width={'95%'} src={MobileIdAndroid} />
                    </a>
                </section>

            </Element>



            <section id='clients-screen' ref={scrollPreventRef} className='scrollPrevent' style={{ overflowX: 'hidden', display: 'flex', flexDirection: 'column', paddingTop: '90px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: '30px', paddingRight: '25px' }}>

                    <p
                        className='title-text whitneybold'
                        ref={titleRef3}
                        style={{
                            paddingTop: '50px',
                            zIndex: 100,
                            display: scrollPreventText ? 'flex' : 'none',
                            flexDirection: 'column',
                            opacity: scrollPreventSticky ? 0 : 1

                        }}
                    >
                        Clients
                        <div
                            style={{
                                width: scrollPreventSticky ? '20px' : '',
                                height: scrollPreventSticky ? '2px' : '',
                            }}
                            className='text-line'
                        ></div>
                    </p>
                    <img width={'25%'} src={newSvg} />

                </div>
                <p
                    className='title-text whitneybold'
                    ref={titleRef3}
                    style={{
                        position: 'fixed',
                        top: '3.5%',
                        zIndex: 100,
                        display: scrollPreventText ? 'flex' : 'none',
                        flexDirection: 'column',
                        fontSize: '20px',
                        opacity: scrollPreventSticky ? 1 : 0,
                        paddingLeft: '23px'
                    }}
                >
                    Clients
                    <div
                        style={{
                            width: scrollPreventSticky ? '20px' : '',
                            height: scrollPreventSticky ? '2px' : '',
                        }}
                        className='text-line'
                    ></div>
                </p>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div className='client-images'>
                        <img width={"15%"} height={'50%'} src={Cocacola} style={{ margin: '30px 0', opacity: 0.1 }} />
                        <img width={"25%"} src={Cocacola} style={{ margin: '30px 0' }} />
                        <img width={"15%"} height={'50%'} src={Cocacola} style={{ margin: '30px 0', opacity: 0.1 }} />

                    </div>
                    <div className='client-images'>
                        <img width={"15%"} height={'50%'} src={hummel} style={{ margin: '40px 0', opacity: 0.1 }} />
                        <img width={"25%"} src={hummel} style={{ margin: '40px 0' }} />
                        <img width={"15%"} height={'50%'} src={hummel} style={{ margin: '40px 0', opacity: 0.1 }} />
                    </div>
                    {/* <div className='client-images'>
                        <img width={"15%"} height={'50%'} src={leo} style={{ margin: '40px 0', opacity: 0.1 }} />
                        <img width={"25%"} src={leo} style={{ margin: '40px 0' }} />
                        <img width={"15%"} height={'50%'} src={leo} style={{ margin: '40px 0', opacity: 0.1 }} />
                    </div> */}
                    <div className='client-images'>
                        <img width={"15%"} height={'50%'} src={threedr} style={{ margin: '40px 0', opacity: 0.1 }} />
                        <img width={"25%"} src={threedr} style={{ margin: '40px 0' }} />
                        <img width={"15%"} height={'50%'} src={threedr} style={{ margin: '40px 0', opacity: 0.1 }} />
                    </div>
                    <div className='client-images'>
                        <img width={"15%"} height={'50%'} src={bpk} style={{ margin: '40px 0', opacity: 0.1 }} />
                        <img width={"18%"} src={bpk} style={{ margin: '40px 0' }} />
                        <img width={"15%"} height={'50%'} src={bpk} style={{ margin: '40px 0', opacity: 0.1 }} />
                    </div>
                    {/* <div className='client-images'>
                        <img width={"15%"} height={'50%'} src={Qogno} style={{ margin: '40px 0', opacity: 0.1 }} />
                        <img width={"25%"} src={Qogno} style={{ margin: '40px 0' }} />
                        <img width={"15%"} height={'50%'} src={Qogno} style={{ margin: '40px 0', opacity: 0.1 }} />
                    </div> */}
                    <div className='client-images'>
                        <img width={"15%"} height={'50%'} src={HelloWorld} style={{ margin: '40px 0', opacity: 0.1 }} />
                        <img width={"25%"} src={HelloWorld} style={{ margin: '40px 0' }} />
                        <img width={"15%"} height={'50%'} src={HelloWorld} style={{ margin: '40px 0', opacity: 0.1 }} />
                    </div>
                    <div className='client-images'>
                        <img width={"15%"} height={'50%'} src={cardServe} style={{ margin: '40px 0', opacity: 0.1 }} />
                        <img width={"25%"} src={cardServe} style={{ margin: '40px 0' }} />
                        <img width={"15%"} height={'50%'} src={cardServe} style={{ margin: '40px 0', opacity: 0.1 }} />
                    </div>
                    <div className='client-images'>
                        <img width={"13%"} src={TDC} style={{ margin: '40px 0', opacity: 0.1 }} />
                        <img width={"21%"} src={TDC} style={{ margin: '40px 0' }} />
                        <img width={"13%"} src={TDC} style={{ margin: '40px 0', opacity: 0.1 }} />
                    </div>
                    <div className='client-images'>
                        <img width={"13%"} src={matlabs} style={{ margin: '40px 0', opacity: 0.1 }} />
                        <img width={"21%"} src={matlabs} style={{ margin: '40px 0' }} />
                        <img width={"13%"} src={matlabs} style={{ margin: '40px 0', opacity: 0.1 }} />
                    </div>
                    <div className='client-images'>
                        <img width={"13%"} src={Proto} style={{ margin: '40px 0', opacity: 0.1 }} />
                        <img width={"21%"} src={Proto} style={{ margin: '40px 0' }} />
                        <img width={"13%"} src={Proto} style={{ margin: '40px 0', opacity: 0.1 }} />
                    </div>
                    <div className='client-images'>
                        <img width={"13%"} src={logo1} style={{ margin: '40px 0', opacity: 0.1 }} />
                        <img width={"21%"} src={logo1} style={{ margin: '40px 0' }} />
                        <img width={"13%"} src={logo1} style={{ margin: '40px 0', opacity: 0.1 }} />
                    </div>
                    <div className='client-images'>
                        <img width={"13%"} src={logo2} style={{ margin: '40px 0', opacity: 0.1 }} />
                        <img width={"21%"} src={logo2} style={{ margin: '40px 0' }} />
                        <img width={"13%"} src={logo2} style={{ margin: '40px 0', opacity: 0.1 }} />
                    </div>


                </div>
            </section>

            <section style={{ overflowX: 'hidden', width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'end', paddingLeft: '30px', paddingBottom: '25px' }}>
                <Footer />
            </section>

        </div>
    );
};

export default Qnix;
