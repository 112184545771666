
import React, { useState, useRef } from "react";
import { Button, Modal, Input, DatePicker, TimePicker, message } from 'antd';
import CareerImage from "./assets/career.svg"
import AttachmentImage from "./assets/attachment.svg"


import moment from 'moment';

const { TextArea } = Input;
const CareerModal = ({ isModalOpen, setIsModalOpen }) => {
    const fileInputRef = useRef(null);
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleAttachResumeClick = () => {
        fileInputRef.current.click();
    };

    const handleFileInputChange = (event) => {
        // Handle the selected file here if needed
        // console.log("Selected file:", event.target.files[0].name);
    };

    const handleSubmit = () => {
        message.success("Form submitted successfully!")
        handleCancel()
    }

    return (
        <Modal mask={false} wrapClassName="custom-modal-wrapper" className="query-modal" footer={false} style={{ maxHeight: '100vh', borderRadius: 20, left: '15px', top: '25%', zIndex: 999 }} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <div style={{ position: 'absolute', top: '-176px', left: '26vw', zIndex: -1 }}>
                <img src={CareerImage} />
            </div>

            <div style={{ paddingTop: '20px' }} />
            <span className="WhitneySemiboldSc" style={{ fontSize: '8vw', width: '90px', paddingTop: '40px' }}>Career At <span style={{ color: '#6A6A6A', fontFamily: 'poppins', fontWeight: 700, opacity: 0.5 }}>Qnix</span></span>

            <div style={{ paddingTop: '30px' }} />

            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Name" />
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Age" />

            {/* <TextArea bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} rows={4} placeholder="Query" maxLength={6} /> */}
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Email-id" />
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Phone no." />
            <Input
                bordered={false}
                style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }}
                placeholder="Attach Resume"
                onClick={handleAttachResumeClick}
                suffix={<img src={AttachmentImage} alt="attachment-icon" />}
            />
            <input
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileInputChange}
                accept=".pdf, .doc, .docx" // Specify the accepted file types if needed
            />
            {/* <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Call Date" />
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Call Time" /> */}
            <div style={{ paddingTop: '5vh' }} />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button onClick={handleSubmit} style={{ backgroundColor: 'black', color: 'white', borderRadius: 12 }}>Submit</Button>

            </div>
        </Modal>
    );
};

export default CareerModal;
