
import React, { useState } from "react";
import { Button, Modal, Input, DatePicker, TimePicker, message } from 'antd';
import CareerImage from "./assets/career.svg"
import ContactImage from "./assets/contact.svg"
import ContactName from "./assets/contact-name.svg"



import moment from 'moment';

const { TextArea } = Input;
const ContactModal = ({ isModalOpen, setIsModalOpen }) => {

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleSubmit = () => {
        message.success("Form submitted successfully!")
        handleCancel()
    }

    return (
        <Modal mask={false} wrapClassName="custom-modal-wrapper" className="query-modal" footer={false} style={{ maxHeight: '100vh', borderRadius: 20, left: '15px',  zIndex: 999 }} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <div style={{ position: 'absolute', bottom: '-20px', left: '40vw' }}>
                <img src={ContactImage} />
            </div>

            <div style={{ paddingTop: '20px' }} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <span className="WhitneySemiboldSc" style={{ fontSize: '5.6vw', width: '90px', paddingTop: '10px' }}>Contact</span>

                {/* <img style={{ objectFit: 'contain' }} src={ContactName} /> */}

            </div>
            <div style={{ paddingTop: '30px' }} />

            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Name" />
            <TextArea bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} rows={4} placeholder="Message" maxLength={6} />

            {/* <TextArea bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} rows={4} placeholder="Query" maxLength={6} /> */}
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Email-id" />
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Phone no." />

            {/* <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Call Date" />
            <Input bordered={false} style={{ borderBottom: '2px solid black', margin: '2vh 0', borderRadius: 0 }} placeholder="Call Time" /> */}
            <div style={{ paddingTop: '6vh' }} />
            <div>
                <Button onClick={handleSubmit} style={{ backgroundColor: 'black', color: 'white', borderRadius: 12 }}>Submit</Button>

            </div>
            <div style={{ paddingTop: '6vh' }} />
        </Modal>
    );
};

export default ContactModal;
