import React, { useState, useEffect } from 'react';
import gsap from 'gsap';
import { Link, animateScroll as scroll } from 'react-scroll';


const Header = ({ backgroundColor, transform, scrollToSection }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const headerAnimation = gsap.timeline();

    if (transform) {
      // If the transform prop is provided, animate the header to move left and disappear
      headerAnimation.to('.brand-logo', { duration: 1, y: -100, opacity: 0 });
    } else {
      // If no transform prop, reset the header position and opacity
      headerAnimation.to('.brand-logo', { duration: 1, y: 0, opacity: 1 });
    }
  }, [transform]);

  // Function to handle the menu toggle
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);

    const menuBar = gsap.timeline();

    if (isMenuOpen) {
      // Create a timeline for the closing animation
      const closeMenu = gsap.timeline();
      closeMenu.to(".fullpage-menu", { duration: 0.5, opacity: 0, display: 'none' });
      closeMenu.to(".box-1", 0.5, { width: '7px', height: '7px', rotate: "0deg", y: 0, x: 0 }, "start");
      closeMenu.to(".box-2", 0.5, { autoAlpha: 1 }, "start");
      closeMenu.to(".box-3", 0.5, { width: '7px', height: '7px', rotate: "0deg", y: 0, x: 0 }, "start");
      closeMenu.to(".box-4", 0.5, { autoAlpha: 1 }, "start");

      menuBar.add(closeMenu);
    } else {
      menuBar.to(".fullpage-menu", { duration: 0.3, opacity: 1, display: 'block' });
      menuBar.to(".box-1", 0.5, { width: 20, height: 3, rotate: "45deg", y: 5, x: 1 }, "start");
      menuBar.to(".box-2", 0.5, { autoAlpha: 0 }, "start");
      menuBar.to(".box-3", 0.5, { width: 20, height: 3, rotate: "-45deg", y: -5, x: 1 }, "start");
      menuBar.to(".box-4", 0.5, { autoAlpha: 0 }, "start");
    }
  };
  const scrolling = (section) => {
    scrollToSection(section)
    toggleMenu()

  }

  const scrollToBottom = () => {
    toggleMenu()
    scroll.scrollToBottom({
      duration: 100, // Adjust the duration as needed
      smooth: 'easeInOutQuart', // Use a smooth easing function
    });
  };
  return (
    <div>
      <header>
        <div className="header-row">
          <div className="brand-logo">
            <a href="/" style={{ color: isMenuOpen ? 'white' : backgroundColor ? backgroundColor : 'black' }} id="logo" className="logo">{"Qnix"}</a>
          </div>

          <button className="menu-toggle" id="menuToggle" onClick={toggleMenu}>
            <span style={{ backgroundColor: isMenuOpen ? 'white' : backgroundColor ? backgroundColor : 'black' }} className="box-1"></span>
            <span style={{ backgroundColor: isMenuOpen ? 'white' : backgroundColor ? backgroundColor : 'black' }} className="box-2"></span>
            <span style={{ backgroundColor: isMenuOpen ? 'white' : backgroundColor ? backgroundColor : 'black' }} className="box-3"></span>
            <span style={{ backgroundColor: isMenuOpen ? 'white' : backgroundColor ? backgroundColor : 'black' }} className="box-4"></span>
          </button>
        </div>
      </header>

      <section style={{ backgroundColor: 'black' }} className={`fullpage-menu`}>
        <div className="fullpage-menu-inner">
          <div className="menu-bg">
            <span></span>
            <span></span>
            <span></span>
          </div>

          <nav>
            <ul className="main-menu">
              <li><a href='/'>Home</a></li>
              <li><a onClick={() => scrolling('Work')}>Work</a></li>
              <li><a onClick={() => scrolling('Service')}>SERVICES</a></li>
              <li><a onClick={scrollToBottom}>Contact</a></li>
            </ul>
          </nav>

          <div className="header-nav-footer">
            <ul className="social-links">
              <li><a >Facebook</a></li>
              <li><a >Instagram</a></li>
              <li><a >Twitter</a></li>
              <li><a >&copy;2021</a></li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Header;
