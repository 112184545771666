import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Qnix from './views';
import WorkSpace from './views/workspace';
import People from './views/people';
import Steel from './views/steel';
import UxDesign from './views/uxDesign';
import Development from './views/development';
import Automation from './views/automation';
import AiMl from './views/ai';
import SiteMaster from './views/sitemaster';
import SteelMobile from './views/steel-mobile';
import Work from './views/work';
import Services from './views/services';
import "./views/style.scss"


function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Qnix />} />
          <Route path="/WorkSpace" element={<WorkSpace />} />
          <Route path="/People" element={<People />} />
          <Route path="/Steel" element={<Steel />} />
          <Route path="/UxDesign" element={<UxDesign />} />
          <Route path="/Development" element={<Development />} />
          <Route path="/Automation" element={<Automation />} />
          <Route path="/AiMl" element={<AiMl />} />
          <Route path="/SiteMaster" element={<SiteMaster />} />
          <Route path="/SteelMobile" element={<SteelMobile />} />
          <Route path="/Work" element={<Work />} />
          <Route path="/Services" element={<Services />} />



        </Routes>
      </Router>
  );
}

export default App;
