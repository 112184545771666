import React from 'react';

const Button = () => {
  const buttonStyle = {
    height: '25px',
    width: '75px',
    borderRadius: '7px',
    border: '1px solid #999',
    backgroundColor: 'white',
    textAlign: 'center',
    lineHeight: '25px',
    fontWeight: '100',
    fontSize: '13px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    color : 'black'
  };
  
  
  return (
    <button style={buttonStyle}>
      more
    </button>
  );
};

export default Button;
